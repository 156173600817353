import React, { useState, useEffect } from 'react';
import { db } from './firebaseConfig';
import { collection, getDocs, query, where, addDoc } from 'firebase/firestore';
import { TextField, Button, Box, Autocomplete } from '@mui/material';

const RegistrarAbono = () => {
  const [clientes, setClientes] = useState([]);
  const [clienteSeleccionado, setClienteSeleccionado] = useState(null);
  const [montoAbono, setMontoAbono] = useState('');
  const [fecha, setFecha] = useState(new Date().toISOString().slice(0, 10)); // Fecha actual del dispositivo

  // Cargar clientes activos desde Firebase
  useEffect(() => {
    const obtenerClientesActivos = async () => {
      const q = query(collection(db, 'clientes'), where('activo', '==', true)); // Solo clientes activos
      const querySnapshot = await getDocs(q);
      setClientes(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    obtenerClientesActivos();
  }, []);

  // Función para registrar el abono
  const registrarAbono = async () => {
    if (!clienteSeleccionado || !montoAbono) {
      alert('Debe seleccionar un cliente y escribir un monto.');
      return;
    }

    const abonoData = {
      fecha, // Usamos la fecha del dispositivo
      clienteId: clienteSeleccionado.id,
      monto: parseFloat(montoAbono),
      estado: 'Pendiente', // Inicialmente en estado "Pendiente"
    };

    try {
      await addDoc(collection(db, 'abonos'), abonoData);
      alert('Abono registrado correctamente.');
      setClienteSeleccionado(null);
      setMontoAbono('');
      setFecha(new Date().toISOString().slice(0, 10)); // Reiniciamos la fecha al valor actual
    } catch (error) {
      console.error('Error al registrar el abono:', error);
    }
  };

  return (
    <Box
      sx={{
        mt: 4,
        p: 3,
        backgroundColor: 'white',
        borderRadius: 2,
        boxShadow: 3,
        maxWidth: '800px',
        margin: '0 auto',
        width: '100%',
      }}
    >
      <h2>Registrar Abono</h2>

      {/* Autocomplete para buscar clientes activos */}
      <Autocomplete
        options={clientes}
        getOptionLabel={(option) => option.nombre}
        value={clienteSeleccionado}
        onChange={(event, newValue) => setClienteSeleccionado(newValue)}
        renderInput={(params) => (
          <TextField {...params} label="Buscar Cliente Activo" margin="normal" fullWidth />
        )}
        fullWidth
      />

      <TextField
        label="Monto del Abono"
        type="number"
        value={montoAbono}
        onChange={(e) => setMontoAbono(e.target.value)}
        margin="normal"
        fullWidth
      />

      <TextField
        label="Fecha"
        type="date"
        value={fecha}
        onChange={(e) => setFecha(e.target.value)} // Permitir la edición de la fecha
        margin="normal"
        fullWidth
      />

      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={registrarAbono}
        sx={{ mt: 2 }}
      >
        Registrar Abono
      </Button>
    </Box>
  );
};

export default RegistrarAbono;
