// src/firebaseConfig.js

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";  // Importar Firestore
import { getAuth } from "firebase/auth";            // Opcional, para autenticación

// Configuración de Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBM9nuzuDUUQsd5uirTfdM44ppcjANSwJk",
  authDomain: "ventamaiz-65d55.firebaseapp.com",
  projectId: "ventamaiz-65d55",
  storageBucket: "ventamaiz-65d55.appspot.com",
  messagingSenderId: "87816411633",
  appId: "1:87816411633:web:bd64d89ba2f56bb35a4c50"
};

// Inicializar Firebase
const app = initializeApp(firebaseConfig);

// Inicializar Firestore y exportarlo
export const db = getFirestore(app);

// Opcional: Inicializar Auth y exportarlo si lo necesitas
export const auth = getAuth(app);
