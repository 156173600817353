import React, { useState, useEffect } from 'react';
import { db } from './firebaseConfig';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { TextField, Button, Box, Typography } from '@mui/material';

const ReporteCompras = () => {
  const [fechaInicio, setFechaInicio] = useState('');
  const [fechaFin, setFechaFin] = useState('');
  const [reporte, setReporte] = useState([]);
  const [totalCamionadas, setTotalCamionadas] = useState(0);

  // Función para cargar las compras en el rango de fechas
  const generarReporte = async () => {
    try {
      if (!fechaInicio || !fechaFin) {
        alert('Por favor, selecciona un rango de fechas válido.');
        return;
      }

      // Convertir fechas a formato adecuado para comparar en Firestore
      const fechaInicioDate = new Date(fechaInicio);
      const fechaFinDate = new Date(fechaFin);
      fechaFinDate.setHours(23, 59, 59, 999); // Asegurarse de incluir todo el día de la fecha de fin

      const comprasRef = collection(db, 'compras');
      const q = query(
        comprasRef,
        where('fecha', '>=', fechaInicio),
        where('fecha', '<=', fechaFin)
      );

      const querySnapshot = await getDocs(q);

      let comprasEnRango = [];
      const comprasPromises = querySnapshot.docs.map(async (doc) => {
        const compra = { id: doc.id, ...doc.data() };

        // Obtener detalles de la compra
        const detallesSnapshot = await getDocs(collection(db, `compras/${doc.id}/detalles`));
        const detalles = detallesSnapshot.docs.map((detalleDoc) => detalleDoc.data());

        compra.detalles = detalles; // Agregar detalles a la compra
        return compra;
      });

      // Esperar a que todas las promesas se resuelvan
      comprasEnRango = await Promise.all(comprasPromises);

      setReporte(comprasEnRango);
      setTotalCamionadas(comprasEnRango.length); // Calcular la cantidad de camionadas

    } catch (error) {
      console.error('Error al generar el reporte: ', error);
    }
  };

  return (
    <Box sx={{ mt: 4, p: 3, backgroundColor: 'white', borderRadius: 2, boxShadow: 3, maxWidth: '800px', margin: '0 auto', width: '100%' }}>
      <h2>Reporte de Compras por Rango de Fechas</h2>

      <TextField
        fullWidth
        label="Fecha de Inicio"
        type="date"
        value={fechaInicio}
        onChange={(e) => setFechaInicio(e.target.value)}
        margin="normal"
        InputLabelProps={{ shrink: true }}
      />

      <TextField
        fullWidth
        label="Fecha de Fin"
        type="date"
        value={fechaFin}
        onChange={(e) => setFechaFin(e.target.value)}
        margin="normal"
        InputLabelProps={{ shrink: true }}
      />

      <Button variant="contained" color="primary" fullWidth onClick={generarReporte} sx={{ mt: 2 }}>
        Generar Reporte
      </Button>

      {/* Mostrar la cantidad de camionadas */}
      <Typography variant="h6" sx={{ mt: 4 }}>Total de Camionadas en el Rango: {totalCamionadas}</Typography>

      {/* Mostrar el reporte de las compras */}
      {reporte.length > 0 && (
        <table style={{ width: '100%', marginTop: '20px', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th style={{ borderBottom: '1px solid black', padding: '8px' }}>Número de Camionada</th>
              <th style={{ borderBottom: '1px solid black', padding: '8px' }}>Fecha</th>
              <th style={{ borderBottom: '1px solid black', padding: '8px' }}>Cantidad</th>
              <th style={{ borderBottom: '1px solid black', padding: '8px' }}>Tipo de Maíz</th>
              <th style={{ borderBottom: '1px solid black', padding: '8px' }}>Precio Compra</th>
              <th style={{ borderBottom: '1px solid black', padding: '8px' }}>Precio Venta</th>
            </tr>
          </thead>
          <tbody>
            {reporte.map((compra) => (
              <React.Fragment key={compra.id}>
                {/* Mostrar detalles de cada compra */}
                {compra.detalles.map((detalle, index) => (
                  <tr key={index}>
                    {index === 0 && (
                      <td rowSpan={compra.detalles.length} style={{ padding: '8px', textAlign: 'center' }}>
                        {compra.camionadaNumero}
                      </td>
                    )}
                    {index === 0 && (
                      <td rowSpan={compra.detalles.length} style={{ padding: '8px', textAlign: 'center' }}>
                        {compra.fecha}
                      </td>
                    )}
                    <td style={{ padding: '8px', textAlign: 'center' }}>{detalle.cantidad}</td>
                    <td style={{ padding: '8px', textAlign: 'center' }}>{detalle.nombre}</td>
                    <td style={{ padding: '8px', textAlign: 'center' }}>{detalle.precioCompra}</td>
                    <td style={{ padding: '8px', textAlign: 'center' }}>{detalle.precioVenta}</td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      )}
    </Box>
  );
};

export default ReporteCompras;
