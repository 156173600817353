import React, { useState, useEffect } from 'react';
import { db } from './firebaseConfig';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from '@mui/material';
import WarehouseIcon from '@mui/icons-material/Warehouse'; // Importamos el icono

const VistaStockBodega = () => {
  const [bodegas, setBodegas] = useState([]);
  const [bodegaSeleccionada, setBodegaSeleccionada] = useState('');
  const [stockMaiz, setStockMaiz] = useState([]);
  const [tiposMaiz, setTiposMaiz] = useState([]);

  // Cargar las bodegas y los tipos de maíz desde Firebase
  useEffect(() => {
    const obtenerBodegas = async () => {
      const querySnapshot = await getDocs(collection(db, 'bodegas'));
      const bodegasData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // Ordenar las bodegas por nombre o cualquier otro campo
      const bodegasOrdenadas = bodegasData.sort((a, b) => a.nombre.localeCompare(b.nombre));
      setBodegas(bodegasOrdenadas);
    };

    const obtenerTiposMaiz = async () => {
      const querySnapshot = await getDocs(collection(db, 'tipos_maiz'));
      setTiposMaiz(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    obtenerBodegas();
    obtenerTiposMaiz();
  }, []);

  // Función para manejar la selección de la bodega
  const handleSeleccionarBodega = async (bodegaId) => {
    setBodegaSeleccionada(bodegaId);

    if (!tiposMaiz.length) {
      console.error('Tipos de maíz aún no están cargados.');
      return;
    }

    // Obtener los datos de la bodega seleccionada
    const bodegaRef = doc(db, 'bodegas', bodegaId);
    const bodegaDoc = await getDoc(bodegaRef);

    if (bodegaDoc.exists()) {
      const stock = bodegaDoc.data().stock || {};
      const stockDetalle = Object.keys(stock).map(tipoId => {
        const tipoMaiz = tiposMaiz.find(maiz => maiz.id === tipoId);
        const detalleStock = stock[tipoId];
        return {
          nombre: tipoMaiz ? tipoMaiz.nombre : 'Desconocido',
          cantidad: detalleStock.cantidad || 0,
          precioCompra: detalleStock.precioCompra || 0,
          precioVenta: detalleStock.precioVenta || 0,
        };
      });
      setStockMaiz(stockDetalle);
    } else {
      setStockMaiz([]);
      console.error('No existe la bodega');
    }
  };

  return (
    <div>
      <h2>Ver Stock por Bodega</h2>

      {/* Mostrar iconos de las bodegas */}
      <Box sx={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px' }}>
        {bodegas.map((bodega) => (
          <Box key={bodega.id} onClick={() => handleSeleccionarBodega(bodega.id)} sx={{ textAlign: 'center', cursor: 'pointer' }}>
            <WarehouseIcon sx={{ fontSize: 50, color: '#1976d2' }} /> {/* Ícono de bodega */}
            <p>{bodega.nombre}</p>
          </Box>
        ))}
      </Box>

      {stockMaiz.length > 0 && (
        <TableContainer component={Paper} style={{ marginTop: '20px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Tipo de Maíz</TableCell>
                <TableCell>Cantidad</TableCell>
                <TableCell>Precio Compra</TableCell>
                <TableCell>Precio Venta</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stockMaiz.map((maiz, index) => (
                <TableRow key={index}>
                  <TableCell>{maiz.nombre}</TableCell>
                  <TableCell>{maiz.cantidad}</TableCell>
                  <TableCell>{maiz.precioCompra}</TableCell>
                  <TableCell>{maiz.precioVenta}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {stockMaiz.length === 0 && bodegaSeleccionada && (
        <p>No hay maíz registrado en esta bodega.</p>
      )}
    </div>
  );
};

export default VistaStockBodega;
