import React, { useState, useEffect } from 'react';
import { db } from './firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import { Box, Table, TableHead, TableRow, TableCell, TableBody, TextField, Button } from '@mui/material';

const VistaClientes = () => {
  const [clientes, setClientes] = useState([]);
  const [filtro, setFiltro] = useState('');
  const [clientesFiltrados, setClientesFiltrados] = useState([]);

  useEffect(() => {
    const obtenerClientes = async () => {
      const querySnapshot = await getDocs(collection(db, 'clientes'));
      const clientesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setClientes(clientesData);
      setClientesFiltrados(clientesData); // Inicialmente mostrar todos los clientes
    };

    obtenerClientes();
  }, []);

  // Función para manejar el filtro por nombre o DPI
  const handleFiltro = (e) => {
    const valorFiltro = e.target.value.toLowerCase();
    setFiltro(valorFiltro);

    // Filtrar por nombre o DPI
    const clientesFiltrados = clientes.filter(cliente =>
      cliente.nombre.toLowerCase().includes(valorFiltro) || cliente.dpi.toLowerCase().includes(valorFiltro)
    );
    setClientesFiltrados(clientesFiltrados);
  };

  return (
    <Box sx={{ mt: 4, p: 3, backgroundColor: 'white', borderRadius: 2, boxShadow: 3, maxWidth: '800px', margin: '0 auto', width: '100%' }}>
      <h2>Lista de Clientes</h2>

      {/* Campo de búsqueda */}
      <TextField
        label="Buscar por nombre o DPI"
        value={filtro}
        onChange={handleFiltro}
        margin="normal"
        fullWidth
      />

      <Table sx={{ mt: 2 }}>
        <TableHead>
          <TableRow>
            <TableCell>Nombre</TableCell>
            <TableCell>Teléfono</TableCell>
            <TableCell>DPI</TableCell>
            <TableCell>Deuda</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {clientesFiltrados.length > 0 ? (
            clientesFiltrados.map(cliente => (
              <TableRow key={cliente.id}>
                <TableCell>{cliente.nombre}</TableCell>
                <TableCell>{cliente.telefono}</TableCell>
                <TableCell>{cliente.dpi}</TableCell>
                <TableCell>{cliente.deuda}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5} align="center">No se encontraron clientes</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Box>
  );
};

export default VistaClientes;
