import React, { useState, useEffect } from 'react';
import { db } from './firebaseConfig';
import { collection, getDocs, doc, getDoc, addDoc, updateDoc } from 'firebase/firestore';
import { TextField, Button, Box, MenuItem, Select, InputLabel, FormControl, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete'; // Importar el ícono de basurero

const RegistrarCompra = () => {
  const [bodegas, setBodegas] = useState([]);
  const [tiposMaiz, setTiposMaiz] = useState([]);
  const [bodegaSeleccionada, setBodegaSeleccionada] = useState('');
  const [fecha, setFecha] = useState('');
  const [camionadaNumero, setCamionadaNumero] = useState(1); // Número de camionada

  const [detallesCompra, setDetallesCompra] = useState([]);
  const [nuevoDetalle, setNuevoDetalle] = useState({
    tipoId: '',
    nombre: '',
    cantidad: 1,
    precioCompra: '', // Inicializado como vacío en lugar de 0
    precioVenta: '',  // Inicializado como vacío en lugar de 0
  });

  // Obtener la fecha del dispositivo
  useEffect(() => {
    const obtenerFechaDispositivo = () => {
      const fechaActual = new Date();
      const year = fechaActual.getFullYear();
      const month = (fechaActual.getMonth() + 1).toString().padStart(2, '0');
      const day = fechaActual.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    setFecha(obtenerFechaDispositivo());
  }, []);

  // Cargar bodegas y tipos de maíz activos desde Firebase
  useEffect(() => {
    const obtenerBodegas = async () => {
      const querySnapshot = await getDocs(collection(db, 'bodegas'));
      setBodegas(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    const obtenerTiposMaizActivos = async () => {
      const querySnapshot = await getDocs(collection(db, 'tipos_maiz'));
      // Filtrar los tipos de maíz que están activos
      const tiposActivos = querySnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(tipo => tipo.activo); // Filtrar por 'activo'
      setTiposMaiz(tiposActivos);
    };

    obtenerBodegas();
    obtenerTiposMaizActivos();
  }, []);

  // Obtener el número de camionada (se incrementa con cada compra)
  useEffect(() => {
    const obtenerNumeroCamionada = async () => {
      const querySnapshot = await getDocs(collection(db, 'compras'));
      const totalCamionadas = querySnapshot.docs.length;
      setCamionadaNumero(totalCamionadas + 1); // Incrementar número de camionada
    };

    obtenerNumeroCamionada();
  }, []);

  // Función para manejar el cambio de los detalles
  const handleChangeDetalle = (field, value) => {
    setNuevoDetalle({
      ...nuevoDetalle,
      [field]: value,
    });
  };

  // Función para cargar el precio de compra al seleccionar el tipo de maíz
  const handleSeleccionarTipoMaiz = async (tipoId) => {
    setNuevoDetalle((prevDetalle) => ({ ...prevDetalle, tipoId }));

    const tipoMaizDoc = await getDoc(doc(db, 'tipos_maiz', tipoId));
    if (tipoMaizDoc.exists()) {
      const tipoMaiz = tipoMaizDoc.data();
      setNuevoDetalle({
        tipoId: tipoId,
        nombre: tipoMaiz.nombre,
        cantidad: 1,
        precioCompra: tipoMaiz.precioCompra || '', // Dejar vacío si no hay valor
        precioVenta: tipoMaiz.precioVenta || '',   // Dejar vacío si no hay valor
      });
    }
  };

  // Función para agregar un producto al detalle de la compra
  const agregarDetalle = () => {
    const subtotal = nuevoDetalle.cantidad * parseFloat(nuevoDetalle.precioCompra || 0); // Parsear a número o usar 0
    setDetallesCompra([...detallesCompra, { ...nuevoDetalle, subtotal }]);
    setNuevoDetalle({
      tipoId: '',
      nombre: '',
      cantidad: 1,
      precioCompra: '', // Limpiar campo de precio de compra
      precioVenta: '',  // Limpiar campo de precio de venta
    });
  };

  // Función para eliminar un producto del detalle de compra
  const eliminarDetalle = (index) => {
    const nuevosDetalles = detallesCompra.filter((_, i) => i !== index);
    setDetallesCompra(nuevosDetalles);
  };

  // Función para calcular el total de la compra
  const calcularTotal = () => {
    return detallesCompra.reduce((total, detalle) => total + detalle.subtotal, 0);
  };

  // Función para aumentar el stock de la bodega seleccionada
  const aumentarStockBodega = async (tipoId, cantidad, precioVenta) => {
    const bodegaRef = doc(db, 'bodegas', bodegaSeleccionada);
    const bodegaDoc = await getDoc(bodegaRef);

    if (bodegaDoc.exists()) {
      const bodegaData = bodegaDoc.data();
      const stockActual = bodegaData.stock || {}; // Verificar si ya existe el campo 'stock'

      // Obtener el stock actual del tipo de maíz o inicializarlo a 0 si no existe
      const stockTipoMaizActual = stockActual[tipoId]?.cantidad || 0;

      // Aumentar el stock sumando la cantidad
      const nuevoStock = stockTipoMaizActual + cantidad;

      // Actualizar el stock en Firebase
      await updateDoc(bodegaRef, {
        [`stock.${tipoId}.cantidad`]: nuevoStock,        // Actualizar la cantidad del tipo de maíz en la bodega seleccionada
        [`stock.${tipoId}.precioVenta`]: precioVenta,    // Actualizar el precio de venta del tipo de maíz
      });
    } else {
      console.error('No se pudo encontrar la bodega seleccionada');
    }
  };

  // Función para registrar la compra en Firebase
  const registrarCompra = async () => {
    try {
      const compraData = {
        fecha, // Usar la fecha del dispositivo
        bodegaId: bodegaSeleccionada,
        camionadaNumero: camionadaNumero, // Número de camionada
        total: calcularTotal(),
      };

      // Registrar la compra (encabezado)
      const compraRef = await addDoc(collection(db, 'compras'), compraData);

      // Registrar los detalles de la compra y aumentar el stock
      const detallesPromises = detallesCompra.map(async (detalle) => {
        await addDoc(collection(db, `compras/${compraRef.id}/detalles`), {
          tipoId: detalle.tipoId,
          nombre: detalle.nombre,
          cantidad: detalle.cantidad,
          precioCompra: detalle.precioCompra,
          precioVenta: detalle.precioVenta, // Guardar el precio de venta en los detalles
          subtotal: detalle.subtotal,
        });

        // Aumentar el stock del tipo de maíz en la bodega seleccionada
        await aumentarStockBodega(detalle.tipoId, detalle.cantidad, detalle.precioVenta);
      });

      await Promise.all(detallesPromises);

      alert('Compra registrada y stock actualizado con éxito');
      // Resetear el formulario
      setBodegaSeleccionada('');
      setFecha(new Date().toISOString().slice(0, 10)); // Establecer de nuevo la fecha actual
      setDetallesCompra([]);
      setCamionadaNumero(camionadaNumero + 1); // Incrementar el número de camionada
    } catch (error) {
      console.error('Error al registrar la compra: ', error);
    }
  };

  return (
    <Box
      sx={{
        mt: 4,
        p: 3,
        backgroundColor: 'white',
        borderRadius: 2,
        boxShadow: 3,
        maxWidth: '800px',
        margin: '0 auto',
        width: '100%',
      }}
    >
      <h2>Registrar Compra</h2>

      <FormControl fullWidth margin="normal">
        <InputLabel>Bodega</InputLabel>
        <Select
          value={bodegaSeleccionada}
          onChange={(e) => setBodegaSeleccionada(e.target.value)}
        >
          <MenuItem value="">Seleccione una bodega</MenuItem>
          {bodegas.map((bodega) => (
            <MenuItem key={bodega.id} value={bodega.id}>
              {bodega.nombre}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        fullWidth
        label="Fecha"
        type="date"
        value={fecha}
        onChange={(e) => setFecha(e.target.value)}
        margin="normal"
        disabled // La fecha está gestionada por el dispositivo
      />

      <Typography variant="h6">Número de Camionada: {camionadaNumero}</Typography>

      <h3>Agregar Tipo de Maíz al Detalle</h3>

      <FormControl fullWidth margin="normal">
        <InputLabel>Tipo de Maíz</InputLabel>
        <Select
          value={nuevoDetalle.tipoId}
          onChange={(e) => handleSeleccionarTipoMaiz(e.target.value)}
        >
          <MenuItem value="">Seleccione un tipo de maíz</MenuItem>
          {tiposMaiz.map((maiz) => (
            <MenuItem key={maiz.id} value={maiz.id}>
              {maiz.nombre}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        fullWidth
        label="Cantidad"
        type="number"
        value={nuevoDetalle.cantidad}
        onChange={(e) => handleChangeDetalle('cantidad', parseInt(e.target.value))}
        margin="normal"
      />

      <TextField
        fullWidth
        label="Precio de Compra"
        type="number"
        value={nuevoDetalle.precioCompra || ''} // Mostrar vacío si no hay valor
        onChange={(e) => handleChangeDetalle('precioCompra', parseFloat(e.target.value))}
        margin="normal"
      />

      <TextField
        fullWidth
        label="Precio de Venta"
        type="number"
        value={nuevoDetalle.precioVenta || ''} // Mostrar vacío si no hay valor
        onChange={(e) => handleChangeDetalle('precioVenta', parseFloat(e.target.value))} // Nuevo campo
        margin="normal"
      />

      <Button variant="contained" color="primary" fullWidth onClick={agregarDetalle} sx={{ mt: 2 }}>
        Agregar Tipo de Maíz al Detalle
      </Button>

      <h3>Detalle de la Compra</h3>

      <table style={{ width: '100%', marginTop: '20px' }}>
        <thead>
          <tr>
            <th>Cantidad</th>
            <th>Tipo de Maíz</th>
            <th>Precio Compra</th>
            <th>Precio Venta</th>
            <th>Subtotal</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {detallesCompra.map((detalle, index) => (
            <tr key={index}>
              <td>{detalle.cantidad}</td>
              <td>{detalle.nombre}</td>
              <td>{detalle.precioCompra}</td>
              <td>{detalle.precioVenta}</td>
              <td>{detalle.subtotal}</td>
              <td>
                <IconButton color="error" onClick={() => eliminarDetalle(index)}>
                  <DeleteIcon />
                </IconButton>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <h3>Total: {calcularTotal()}</h3>

      <Button variant="contained" color="secondary" fullWidth onClick={registrarCompra} sx={{ mt: 2 }}>
        Registrar Compra
      </Button>
    </Box>
  );
};

export default RegistrarCompra;
