import React, { useState, useEffect } from 'react';
import { db } from './firebaseConfig';
import { collection, getDocs, doc, getDoc, addDoc, updateDoc } from 'firebase/firestore';
import { TextField, Button, Box, MenuItem, Select, InputLabel, FormControl, Checkbox, FormControlLabel, Autocomplete, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete'; // Importar el ícono de basurero

// Función para obtener la fecha en formato YYYY-MM-DD con la zona horaria local
const obtenerFechaLocal = () => {
  const fecha = new Date();
  const year = fecha.getFullYear();
  const month = (fecha.getMonth() + 1).toString().padStart(2, '0'); // Meses van de 0-11, se ajusta sumando 1
  const day = fecha.getDate().toString().padStart(2, '0'); // Día del mes
  return `${year}-${month}-${day}`;
};

const RegistrarVenta = () => {
  const [bodegas, setBodegas] = useState([]);
  const [tiposMaiz, setTiposMaiz] = useState([]);
  const [clientes, setClientes] = useState([]); // Para almacenar los clientes activos
  const [bodegaSeleccionada, setBodegaSeleccionada] = useState('');
  const [clienteSeleccionado, setClienteSeleccionado] = useState(null); // Cambiado para almacenar el cliente seleccionado como objeto
  const [ventaCredito, setVentaCredito] = useState(false); // Checkbox para venta a crédito o contado
  const [fecha, setFecha] = useState(obtenerFechaLocal()); // Fecha actual por defecto usando la función local
  const [detallesVenta, setDetallesVenta] = useState([]);
  const [nuevoDetalle, setNuevoDetalle] = useState({
    tipoId: '',
    nombre: '',
    cantidad: '', // Por defecto, la cantidad estará vacía
    precioVenta: '', // Por defecto, el precio de venta estará vacío
  });
  const [stockActual, setStockActual] = useState(0); // Stock actual

  useEffect(() => {
    const obtenerBodegas = async () => {
      const querySnapshot = await getDocs(collection(db, 'bodegas'));
      setBodegas(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    const obtenerTiposMaiz = async () => {
      const querySnapshot = await getDocs(collection(db, 'tipos_maiz'));
      setTiposMaiz(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    const obtenerClientes = async () => {
      const querySnapshot = await getDocs(collection(db, 'clientes'));
      const clientesActivos = querySnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(cliente => cliente.activo); // Filtrar solo los clientes activos
      setClientes(clientesActivos); // Almacenar solo los clientes activos
    };

    obtenerBodegas();
    obtenerTiposMaiz();
    obtenerClientes(); // Cargar clientes activos
  }, []);

  // Función para manejar la selección de la bodega
  const handleSeleccionarBodega = async (bodegaId) => {
    setBodegaSeleccionada(bodegaId);
    setStockActual(0); 
    setNuevoDetalle({ tipoId: '', nombre: '', cantidad: '', precioVenta: '' }); // Reiniciar los campos a vacíos
  };

  const handleSeleccionarTipoMaiz = async (maizId) => {
    setNuevoDetalle((prevDetalle) => ({ ...prevDetalle, tipoId: maizId }));

    if (bodegaSeleccionada) {
      const bodegaRef = doc(db, 'bodegas', bodegaSeleccionada);
      const bodegaDoc = await getDoc(bodegaRef);

      if (bodegaDoc.exists()) {
        const bodegaData = bodegaDoc.data();
        const stockData = bodegaData.stock;

        if (stockData && stockData[maizId]) {
          const tipoMaiz = tiposMaiz.find((maiz) => maiz.id === maizId);
          const maizEnStock = stockData[maizId];
          setNuevoDetalle({
            tipoId: maizId,
            nombre: tipoMaiz?.nombre || '',
            cantidad: '',
            precioVenta: maizEnStock.precioVenta || '',
          });
          setStockActual(maizEnStock.cantidad); 
        } else {
          console.error('El tipo de maíz no está disponible en la bodega seleccionada.');
        }
      } else {
        console.error('No se encontró la bodega seleccionada.');
      }
    }
  };

  const agregarDetalle = () => {
    // Validaciones para evitar agregar un detalle con cantidad o precio vacío
    if (!nuevoDetalle.cantidad || nuevoDetalle.cantidad <= 0) {
      alert('Debe ingresar una cantidad válida.');
      return;
    }
    if (!nuevoDetalle.precioVenta || nuevoDetalle.precioVenta <= 0) {
      alert('Debe ingresar un precio de venta válido.');
      return;
    }
    if (nuevoDetalle.cantidad > stockActual) {
      alert('La cantidad seleccionada excede el stock disponible.');
      return;
    }

    const subtotal = nuevoDetalle.cantidad * nuevoDetalle.precioVenta;
    setDetallesVenta([...detallesVenta, { ...nuevoDetalle, subtotal }]);
    setNuevoDetalle({
      tipoId: '',
      nombre: '',
      cantidad: '', // Reiniciamos la cantidad a vacía
      precioVenta: '', // Reiniciamos el precio de venta a vacío
    });
    setStockActual(0);
  };

  const eliminarDetalle = (index) => {
    const nuevosDetalles = detallesVenta.filter((_, i) => i !== index);
    setDetallesVenta(nuevosDetalles);
  };

  const calcularTotal = () => {
    return detallesVenta.reduce((total, detalle) => total + detalle.subtotal, 0);
  };

  const reducirStockBodega = async (tipoId, cantidad) => {
    const bodegaRef = doc(db, 'bodegas', bodegaSeleccionada);
    const bodegaDoc = await getDoc(bodegaRef);

    if (bodegaDoc.exists()) {
      const stockActual = bodegaDoc.data().stock[tipoId]?.cantidad || 0;
      const nuevoStock = stockActual - cantidad;

      if (nuevoStock >= 0) {
        await updateDoc(bodegaRef, {
          [`stock.${tipoId}.cantidad`]: nuevoStock,
        });
      } else {
        console.error('Stock insuficiente');
      }
    } else {
      console.error('No se pudo encontrar la bodega seleccionada');
    }
  };

  const registrarDeudaCliente = async (clienteId, monto) => {
    const clienteRef = doc(db, 'clientes', clienteId);
    const clienteDoc = await getDoc(clienteRef);

    if (clienteDoc.exists()) {
      const clienteData = clienteDoc.data();
      const deudaActual = clienteData.deudaTotal || 0; // Campo deudaTotal
      const nuevaDeuda = deudaActual + monto;

      await updateDoc(clienteRef, {
        deudaTotal: nuevaDeuda, // Actualizar deudaTotal
      });
    }
  };

  const registrarVenta = async () => {
    // Validación para evitar registrar la venta sin detalles
    if (detallesVenta.length === 0) {
      alert('Debe agregar al menos un detalle antes de registrar la venta.');
      return;
    }

    try {
      const ventaData = {
        fecha, // Usamos la fecha local
        bodegaId: bodegaSeleccionada,
        clienteId: clienteSeleccionado?.id, // Guardar el id del cliente
        total: calcularTotal(),
        tipoVenta: ventaCredito ? 'Crédito' : 'Contado',
      };

      const ventaRef = await addDoc(collection(db, 'ventas'), ventaData);

      const detallesPromises = detallesVenta.map(async (detalle) => {
        await addDoc(collection(db, `ventas/${ventaRef.id}/detalles`), {
          tipoId: detalle.tipoId,
          nombre: detalle.nombre,
          cantidad: detalle.cantidad,
          precioVenta: detalle.precioVenta,
          subtotal: detalle.subtotal,
        });

        await reducirStockBodega(detalle.tipoId, detalle.cantidad);
      });

      await Promise.all(detallesPromises);

      if (ventaCredito) {
        await registrarDeudaCliente(clienteSeleccionado.id, calcularTotal());
      }

      alert('Venta registrada y stock actualizado con éxito');
      resetFormulario();
    } catch (error) {
      console.error('Error al registrar la venta: ', error);
    }
  };

  const resetFormulario = () => {
    setBodegaSeleccionada('');
    setClienteSeleccionado(null);
    setVentaCredito(false);
    setDetallesVenta([]);
    setNuevoDetalle({
      tipoId: '',
      nombre: '',
      cantidad: '', // Reiniciar cantidad a vacío
      precioVenta: '', // Reiniciar precio a vacío
    });
    setFecha(obtenerFechaLocal()); // Establecer la fecha local al reiniciar
  };

  return (
    <Box
      sx={{
        mt: 4,
        p: 3,
        backgroundColor: 'white',
        borderRadius: 2,
        boxShadow: 3,
        maxWidth: '800px',
        margin: '0 auto',
        width: '100%',
        pb: 6, // Añadir padding para evitar que el botón se oculte bajo el menú
      }}
    >
      <h2>Registrar Venta</h2>

      <FormControl fullWidth margin="normal">
        <InputLabel>Bodega</InputLabel>
        <Select
          value={bodegaSeleccionada}
          onChange={(e) => handleSeleccionarBodega(e.target.value)}
        >
          <MenuItem value="">Seleccione una bodega</MenuItem>
          {bodegas.map((bodega) => (
            <MenuItem key={bodega.id} value={bodega.id}>
              {bodega.nombre}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth margin="normal">
        <InputLabel>Tipo de Maíz</InputLabel>
        <Select
          value={nuevoDetalle.tipoId}
          onChange={(e) => handleSeleccionarTipoMaiz(e.target.value)}
        >
          <MenuItem value="">Seleccione un tipo de maíz</MenuItem>
          {tiposMaiz.map((maiz) => (
            <MenuItem key={maiz.id} value={maiz.id}>
              {maiz.nombre}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Autocomplete para buscar clientes activos */}
      <Autocomplete
        options={clientes}
        getOptionLabel={(option) => option.nombre} // Mostrar el nombre del cliente
        value={clienteSeleccionado}
        onChange={(event, newValue) => setClienteSeleccionado(newValue)}
        renderInput={(params) => <TextField {...params} label="Cliente" margin="normal" />}
        fullWidth
      />

      <TextField
        fullWidth
        label="Cantidad"
        type="number"
        value={nuevoDetalle.cantidad}
        onChange={(e) => setNuevoDetalle({ ...nuevoDetalle, cantidad: e.target.value })}
        margin="normal"
        placeholder="Ingrese la cantidad"
      />
      <p>Stock Actual: {stockActual}</p>

      <TextField
        fullWidth
        label="Precio Venta"
        type="number"
        value={nuevoDetalle.precioVenta}
        onChange={(e) => setNuevoDetalle({ ...nuevoDetalle, precioVenta: e.target.value })}
        margin="normal"
        placeholder="Ingrese el precio de venta"
      />

      <FormControlLabel
        control={<Checkbox checked={ventaCredito} onChange={(e) => setVentaCredito(e.target.checked)} />}
        label="Venta a Crédito"
      />

      <Button variant="contained" color="primary" fullWidth onClick={agregarDetalle} sx={{ mt: 2 }}>
        Agregar Tipo de Maíz al Detalle
      </Button>

      <h3>Detalle de la Venta</h3>

      <table style={{ width: '100%', marginTop: '20px' }}>
        <thead>
          <tr>
            <th>Cantidad</th>
            <th>Tipo de Maíz</th>
            <th>Precio</th>
            <th>Subtotal</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {detallesVenta.map((detalle, index) => (
            <tr key={index}>
              <td>{detalle.cantidad}</td>
              <td>{detalle.nombre}</td>
              <td>{detalle.precioVenta}</td>
              <td>{detalle.subtotal}</td>
              <td>
                <IconButton color="error" onClick={() => eliminarDetalle(index)}>
                  <DeleteIcon />
                </IconButton>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <h3>Total: {calcularTotal()}</h3>

      <Button variant="contained" color="secondary" fullWidth onClick={registrarVenta} sx={{ mt: 2 }}>
        Registrar Venta
      </Button>
    </Box>
  );
};

export default RegistrarVenta;
