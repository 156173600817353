import React, { useState, useEffect } from 'react';
import { db } from './firebaseConfig';
import { collection, addDoc, onSnapshot, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { Box, Button, TextField, Typography, Alert, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const AgregarBodega = () => {
  const [nombreBodega, setNombreBodega] = useState('');
  const [ubicacionBodega, setUbicacionBodega] = useState('');
  const [mensajeExito, setMensajeExito] = useState('');
  const [mensajeError, setMensajeError] = useState('');
  const [bodegas, setBodegas] = useState([]);
  const [idEditar, setIdEditar] = useState(null);

  // Obtener las bodegas de Firestore en tiempo real
  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'bodegas'), (snapshot) => {
      let listaBodegas = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

      // Ordenar la lista de bodegas por nombre
      listaBodegas = listaBodegas.sort((a, b) => a.nombre.localeCompare(b.nombre));

      setBodegas(listaBodegas);
    });

    return () => unsubscribe();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (idEditar) {
        // Actualizar bodega
        const docRef = doc(db, 'bodegas', idEditar);
        await updateDoc(docRef, {
          nombre: nombreBodega,
          ubicacion: ubicacionBodega,
        });
        setMensajeExito('Bodega actualizada con éxito');
        setIdEditar(null);
      } else {
        // Registrar nueva bodega
        await addDoc(collection(db, 'bodegas'), {
          nombre: nombreBodega,
          ubicacion: ubicacionBodega,
          stock: {}, // Inicialmente vacío hasta que se añadan productos
        });
        setMensajeExito('Bodega agregada exitosamente');
      }
      setNombreBodega('');
      setUbicacionBodega('');
      setMensajeError('');
    } catch (error) {
      setMensajeError('Error al agregar o actualizar la bodega: ' + error.message);
      setMensajeExito('');
    }
  };

  const eliminarBodega = async (id) => {
    try {
      await deleteDoc(doc(db, 'bodegas', id));
      setMensajeExito('Bodega eliminada con éxito');
    } catch (error) {
      setMensajeError('Error al eliminar la bodega: ' + error.message);
    }
  };

  const editarBodega = (bodega) => {
    setNombreBodega(bodega.nombre);
    setUbicacionBodega(bodega.ubicacion);
    setIdEditar(bodega.id);
  };

  return (
    <Box
      sx={{
        mt: 4,
        p: 3,
        backgroundColor: 'white',
        borderRadius: 2,
        boxShadow: 3,
        maxWidth: '800px',
        margin: '0 auto',
        width: '100%',
      }}
    >
      <Typography variant="h4" gutterBottom>
        {idEditar ? 'Actualizar Bodega' : 'Agregar Bodega'}
      </Typography>

      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Nombre de la Bodega"
          value={nombreBodega}
          onChange={(e) => setNombreBodega(e.target.value)}
          margin="normal"
          required
        />

        <TextField
          fullWidth
          label="Ubicación de la Bodega"
          value={ubicacionBodega}
          onChange={(e) => setUbicacionBodega(e.target.value)}
          margin="normal"
          required
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
        >
          {idEditar ? 'Actualizar Bodega' : 'Agregar Bodega'}
        </Button>
      </form>

      {/* Mostrar mensaje de éxito o error */}
      {mensajeExito && (
        <Alert severity="success" sx={{ mt: 2 }}>
          {mensajeExito}
        </Alert>
      )}

      {mensajeError && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {mensajeError}
        </Alert>
      )}

      {/* Tabla de bodegas */}
      <Typography variant="h5" sx={{ mt: 4 }}>
        Lista de Bodegas
      </Typography>

      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>#</strong></TableCell>
              <TableCell><strong>Nombre</strong></TableCell>
              <TableCell><strong>Ubicación</strong></TableCell>
              <TableCell><strong>Acciones</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bodegas.map((bodega, index) => (
              <TableRow key={bodega.id}>
                <TableCell>{index + 1}</TableCell> {/* Número de fila */}
                <TableCell>{bodega.nombre}</TableCell>
                <TableCell>{bodega.ubicacion}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => editarBodega(bodega)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="error" onClick={() => eliminarBodega(bodega.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AgregarBodega;
