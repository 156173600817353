import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { db } from './firebaseConfig';
import { collection, addDoc, onSnapshot, doc, updateDoc } from 'firebase/firestore';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ToggleOnIcon from '@mui/icons-material/ToggleOn'; // Icono de activado
import ToggleOffIcon from '@mui/icons-material/ToggleOff'; // Icono de desactivado

const RegistroCliente = () => {
  const [nombre, setNombre] = useState('');
  const [dpi, setDpi] = useState('');
  const [telefono, setTelefono] = useState('');
  const [deudaTotal, setDeudaTotal] = useState(0); // Campo deuda
  const [clientes, setClientes] = useState([]);
  const [idEditar, setIdEditar] = useState(null);

  // Obtener los clientes de Firestore en tiempo real
  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'clientes'), (snapshot) => {
      const listaClientes = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setClientes(listaClientes);
    });

    return () => unsubscribe();
  }, []);

  const registrarCliente = async () => {
    if (idEditar) {
      // Actualizar cliente
      const docRef = doc(db, 'clientes', idEditar);
      try {
        await updateDoc(docRef, { nombre, dpi, telefono, deudaTotal });
        alert('Cliente actualizado con éxito');
        resetFormulario();
      } catch (error) {
        console.error('Error al actualizar el cliente: ', error);
      }
    } else {
      // Registrar nuevo cliente
      try {
        await addDoc(collection(db, 'clientes'), {
          nombre,
          dpi,
          telefono,
          deudaTotal: parseFloat(deudaTotal) || 0, // Asegurar que sea un número
          activo: true // Cliente activo por defecto
        });
        alert('Cliente registrado con éxito');
        resetFormulario();
      } catch (error) {
        console.error('Error al registrar el cliente: ', error);
      }
    }
  };

  const cambiarEstadoCliente = async (id, estadoActual) => {
    try {
      const docRef = doc(db, 'clientes', id);
      await updateDoc(docRef, { activo: !estadoActual }); // Cambiar el estado activo
      alert(`Cliente ${estadoActual ? 'desactivado' : 'activado'} con éxito`);
    } catch (error) {
      console.error(`Error al cambiar estado del cliente: ${error}`);
    }
  };

  const editarCliente = (cliente) => {
    setNombre(cliente.nombre);
    setDpi(cliente.dpi);
    setTelefono(cliente.telefono);
    setDeudaTotal(cliente.deudaTotal || 0); // Cargar deuda si existe
    setIdEditar(cliente.id);
  };

  const resetFormulario = () => {
    setNombre('');
    setDpi('');
    setTelefono('');
    setDeudaTotal(0); // Reiniciar deuda
    setIdEditar(null);
  };

  return (
    <Box
      sx={{
        mt: 4,
        p: 3,
        backgroundColor: 'white',
        borderRadius: 2,
        boxShadow: 3,
        maxWidth: '800px',
        margin: '0 auto',
        width: '100%'
      }}
    >
      <h2>{idEditar ? 'Actualizar Cliente' : 'Registrar Cliente'}</h2>
      <TextField
        fullWidth
        label="Nombre Completo"
        value={nombre}
        onChange={(e) => setNombre(e.target.value)}
        margin="normal"
      />
      <TextField
        fullWidth
        label="DPI"
        value={dpi}
        onChange={(e) => setDpi(e.target.value)}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Teléfono"
        value={telefono}
        onChange={(e) => setTelefono(e.target.value)}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Deuda Inicial (Q)"
        type="number"
        value={deudaTotal}
        onChange={(e) => setDeudaTotal(parseFloat(e.target.value))}
        margin="normal"
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={registrarCliente}
        sx={{ mt: 2 }}
      >
        {idEditar ? 'Actualizar Cliente' : 'Registrar Cliente'}
      </Button>

      <Typography variant="h5" sx={{ mt: 4 }}>
        Lista de Clientes
      </Typography>

      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>Nombre</strong></TableCell>
              <TableCell><strong>DPI</strong></TableCell>
              <TableCell><strong>Teléfono</strong></TableCell>
              <TableCell><strong>Deuda Total (Q)</strong></TableCell>
              <TableCell><strong>Estado</strong></TableCell>
              <TableCell><strong>Acciones</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clientes.map((cliente) => (
              <TableRow key={cliente.id}>
                <TableCell>{cliente.nombre}</TableCell>
                <TableCell>{cliente.dpi}</TableCell>
                <TableCell>{cliente.telefono}</TableCell>
                <TableCell>{cliente.deudaTotal.toFixed(2)}</TableCell>
                <TableCell>{cliente.activo ? 'Activo' : 'Inactivo'}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => editarCliente(cliente)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color={cliente.activo ? "success" : "error"}
                    onClick={() => cambiarEstadoCliente(cliente.id, cliente.activo)}
                  >
                    {cliente.activo ? <ToggleOnIcon /> : <ToggleOffIcon />}
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default RegistroCliente;
