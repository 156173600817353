import React, { useState, useEffect } from 'react';
import { db } from './firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import { Box, Button, Typography, TextField } from '@mui/material';

const ReporteGastosAbonos = () => {
  const [gastos, setGastos] = useState([]);
  const [abonos, setAbonos] = useState([]);
  const [fechaInicio, setFechaInicio] = useState('');
  const [fechaFin, setFechaFin] = useState('');

  // Función para generar el reporte de gastos y abonos
  const generarReporte = async () => {
    try {
      if (!fechaInicio || !fechaFin) {
        alert('Por favor, selecciona un rango de fechas válido.');
        return;
      }

      const fechaInicioDate = new Date(fechaInicio);
      const fechaFinDate = new Date(fechaFin);
      fechaFinDate.setHours(23, 59, 59, 999);

      // Cargar los gastos
      const gastosSnapshot = await getDocs(collection(db, 'gastos'));
      const gastosFiltrados = gastosSnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(gasto => new Date(gasto.fecha) >= fechaInicioDate && new Date(gasto.fecha) <= fechaFinDate);

      setGastos(gastosFiltrados);

      // Cargar los abonos
      const abonosSnapshot = await getDocs(collection(db, 'abono_empleados'));
      const abonosFiltrados = abonosSnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(abono => new Date(abono.fecha) >= fechaInicioDate && new Date(abono.fecha) <= fechaFinDate);

      setAbonos(abonosFiltrados);

    } catch (error) {
      console.error('Error al generar el reporte:', error);
    }
  };

  return (
    <Box
      sx={{
        mt: 4,
        p: 3,
        backgroundColor: 'white',
        borderRadius: 2,
        boxShadow: 3,
        maxWidth: '800px',
        margin: '0 auto',
        width: '100%',
      }}
    >
      <Typography variant="h4" gutterBottom>Reporte de Gastos y Abonos</Typography>

      <TextField
        fullWidth
        label="Fecha de Inicio"
        type="date"
        value={fechaInicio}
        onChange={(e) => setFechaInicio(e.target.value)}
        margin="normal"
        InputLabelProps={{ shrink: true }}
      />

      <TextField
        fullWidth
        label="Fecha de Fin"
        type="date"
        value={fechaFin}
        onChange={(e) => setFechaFin(e.target.value)}
        margin="normal"
        InputLabelProps={{ shrink: true }}
      />

      <Button variant="contained" color="primary" fullWidth onClick={generarReporte} sx={{ mt: 2 }}>
        Generar Reporte
      </Button>

      {/* Reporte de Gastos */}
      <Typography variant="h5" sx={{ mt: 4 }}>Gastos</Typography>
      {gastos.length > 0 ? (
        <table style={{ width: '100%', marginTop: '20px', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th style={{ borderBottom: '1px solid black', padding: '8px' }}>Fecha</th>
              <th style={{ borderBottom: '1px solid black', padding: '8px' }}>Descripción</th>
              <th style={{ borderBottom: '1px solid black', padding: '8px' }}>Total</th>
            </tr>
          </thead>
          <tbody>
            {gastos.map((gasto) => (
              <tr key={gasto.id}>
                <td style={{ padding: '8px', textAlign: 'center' }}>{gasto.fecha}</td>
                <td style={{ padding: '8px', textAlign: 'center' }}>{gasto.descripcion}</td>
                <td style={{ padding: '8px', textAlign: 'center' }}>{gasto.monto}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <Typography>No se encontraron gastos en el rango de fechas seleccionado.</Typography>
      )}

      {/* Reporte de Abonos */}
      <Typography variant="h5" sx={{ mt: 4 }}>Abonos</Typography>
      {abonos.length > 0 ? (
        <table style={{ width: '100%', marginTop: '20px', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th style={{ borderBottom: '1px solid black', padding: '8px' }}>Fecha</th>
              <th style={{ borderBottom: '1px solid black', padding: '8px' }}>Empleado</th>
              <th style={{ borderBottom: '1px solid black', padding: '8px' }}>Total</th>
            </tr>
          </thead>
          <tbody>
            {abonos.map((abono) => (
              <tr key={abono.id}>
                <td style={{ padding: '8px', textAlign: 'center' }}>{abono.fecha}</td>
                <td style={{ padding: '8px', textAlign: 'center' }}>{abono.empleado}</td>
                <td style={{ padding: '8px', textAlign: 'center' }}>{abono.monto}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <Typography>No se encontraron abonos en el rango de fechas seleccionado.</Typography>
      )}
    </Box>
  );
};

export default ReporteGastosAbonos;
