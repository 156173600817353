import React, { useState, useEffect } from 'react';
import { db } from './firebaseConfig';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { TextField, Button, Box, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';

// Función para obtener la fecha local en formato YYYY-MM-DD
const obtenerFechaLocal = () => {
  const fecha = new Date();
  const year = fecha.getFullYear();
  const month = (fecha.getMonth() + 1).toString().padStart(2, '0'); // Los meses son de 0-11, por lo que sumamos 1
  const day = fecha.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const ReporteVentas = () => {
  const [fechaSeleccionada, setFechaSeleccionada] = useState(''); // Fecha actual
  const [ventas, setVentas] = useState([]);
  const [totalVentasCredito, setTotalVentasCredito] = useState(0);
  const [totalVentasContado, setTotalVentasContado] = useState(0);
  const [totalGastos, setTotalGastos] = useState(0); // Total de gastos
  const [totalAbonos, setTotalAbonos] = useState(0); // Total de abonos de empleados
  const [totalEfectivo, setTotalEfectivo] = useState(0); // Total efectivo (ventas al contado - gastos - abonos)

  // Obtener la fecha del dispositivo y establecerla al cargar la página
  useEffect(() => {
    const fechaDispositivo = obtenerFechaLocal(); // Usar la función de fecha local
    setFechaSeleccionada(fechaDispositivo); // Establecer la fecha actual como predeterminada
  }, []);

  // Función para obtener el nombre de la bodega por su ID
  const obtenerNombreBodega = async (bodegaId) => {
    try {
      const bodegaRef = doc(db, 'bodegas', bodegaId);
      const bodegaDoc = await getDoc(bodegaRef);
      return bodegaDoc.exists() ? bodegaDoc.data().nombre : 'Bodega no encontrada';
    } catch (error) {
      console.error('Error al obtener el nombre de la bodega:', error);
      return 'Bodega no encontrada';
    }
  };

  // Función para consultar ventas por fecha y agruparlas por bodega y tipo de venta
  const obtenerVentasPorFecha = async () => {
    try {
      const ventasRef = collection(db, 'ventas');
      const q = query(ventasRef, where('fecha', '==', fechaSeleccionada)); // Filtrar por fecha exacta
      const querySnapshot = await getDocs(q);

      let ventasAgrupadas = {};
      let totalCredito = 0;
      let totalContado = 0;

      // Recorrer los documentos obtenidos
      for (const ventaDoc of querySnapshot.docs) {
        const venta = ventaDoc.data();
        const { bodegaId, total: ventaTotal, tipoVenta } = venta;

        // Sumar al total de crédito o contado
        if (tipoVenta === 'Crédito') {
          totalCredito += ventaTotal;
        } else {
          totalContado += ventaTotal;
        }

        // Obtener los detalles de cada venta (subcolección 'detalles')
        const detallesSnapshot = await getDocs(collection(db, `ventas/${ventaDoc.id}/detalles`));
        const detallesVenta = detallesSnapshot.docs.map((detalleDoc) => detalleDoc.data());

        // Si la bodega ya está en ventasAgrupadas, sumar sus detalles
        if (ventasAgrupadas[bodegaId]) {
          ventasAgrupadas[bodegaId].detalles.push(...detallesVenta);
          ventasAgrupadas[bodegaId].total += ventaTotal;
          ventasAgrupadas[bodegaId].tipoVenta = tipoVenta;
        } else {
          // Obtener el nombre de la bodega y agregarla al objeto de agrupación
          const nombreBodega = await obtenerNombreBodega(bodegaId);
          ventasAgrupadas[bodegaId] = {
            nombre: nombreBodega,
            detalles: detallesVenta,
            total: ventaTotal,
            tipoVenta: tipoVenta,
          };
        }
      }

      // Convertir el objeto de ventas agrupadas en un array
      const ventasFiltradas = Object.keys(ventasAgrupadas).map((bodegaId) => ({
        nombre: ventasAgrupadas[bodegaId].nombre,
        detalles: ventasAgrupadas[bodegaId].detalles,
        total: ventasAgrupadas[bodegaId].total,
        tipoVenta: ventasAgrupadas[bodegaId].tipoVenta,
      }));

      setVentas(ventasFiltradas);
      setTotalVentasCredito(totalCredito);
      setTotalVentasContado(totalContado);

      // Obtener los gastos y abonos para la fecha seleccionada
      await obtenerGastosYAbonos(totalContado); // Pasamos totalContado para calcular el total efectivo
    } catch (error) {
      console.error('Error al obtener las ventas: ', error);
    }
  };

  // Función para obtener los gastos y abonos de la fecha seleccionada
  const obtenerGastosYAbonos = async (totalContado) => {
    try {
      // Obtener gastos desde la colección 'gastos'
      const gastosSnapshot = await getDocs(
        query(collection(db, 'gastos'), where('fecha', '==', fechaSeleccionada))
      );
      const totalGastos = gastosSnapshot.docs.reduce((total, gastoDoc) => total + gastoDoc.data().monto, 0);
      setTotalGastos(totalGastos);

      // Obtener abonos desde la colección 'abono_empleados'
      const abonosSnapshot = await getDocs(
        query(collection(db, 'abono_empleados'), where('fecha', '==', fechaSeleccionada))
      );
      const totalAbonos = abonosSnapshot.docs.reduce((total, abonoDoc) => total + abonoDoc.data().monto, 0);
      setTotalAbonos(totalAbonos);

      // Calcular el total en efectivo (ventas al contado - gastos - abonos)
      const efectivo = totalContado - totalGastos - totalAbonos;
      setTotalEfectivo(efectivo);
    } catch (error) {
      console.error('Error al obtener gastos y abonos: ', error);
    }
  };

  return (
    <Box
      sx={{
        mt: 4,
        p: 3,
        backgroundColor: 'white',
        borderRadius: 2,
        boxShadow: 3,
        maxWidth: '800px',
        margin: '0 auto',
        width: '100%',
      }}
    >
      <h2>Reporte de Ventas por Fecha</h2>

      {/* Campo para seleccionar la fecha */}
      <TextField
        fullWidth
        label="Fecha"
        type="date"
        value={fechaSeleccionada}
        onChange={(e) => setFechaSeleccionada(e.target.value)}
        margin="normal"
      />

      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={obtenerVentasPorFecha}
        sx={{ mt: 2 }}
      >
        Consultar Ventas
      </Button>

      <h3>Ventas del {fechaSeleccionada}</h3>

      {/* Tabla para mostrar las ventas */}
      <Table sx={{ mt: 2 }}>
        <TableHead>
          <TableRow>
            <TableCell>Bodega</TableCell>
            <TableCell>Tipo de Maíz</TableCell>
            <TableCell>Cantidad</TableCell>
            <TableCell>Subtotal</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ventas.length > 0 ? (
            ventas.map((venta, index) => (
              <React.Fragment key={index}>
                {venta.detalles.map((detalle, detalleIndex) => (
                  <TableRow key={detalleIndex}>
                    <TableCell>{venta.nombre}</TableCell>
                    <TableCell>{detalle.nombre}</TableCell>
                    <TableCell>{detalle.cantidad}</TableCell>
                    <TableCell>Q. {detalle.subtotal.toFixed(2)}</TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5} align="center">
                No se encontraron ventas para esta fecha
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      {/* Mostrar el total de todas las ventas */}
      <h3>Total ventas al Contado: <span style={{ color: 'blue' }}>Q. {totalVentasContado.toFixed(2)}</span></h3>
      <h3>Total ventas al Crédito: <span style={{ color: 'red' }}>Q. {totalVentasCredito.toFixed(2)}</span></h3>
      <h3>Total de ventas del día: <span>Q. {(totalVentasContado + totalVentasCredito).toFixed(2)}</span></h3>

      {/* Mostrar el total de los gastos y abonos */}
      <h3>Total Gastos Varios: <span style={{ color: 'red' }}>Q. {totalGastos.toFixed(2)}</span></h3>
      <h3>Total Abonos Empleados: <span style={{ color: 'red' }}>Q. {totalAbonos.toFixed(2)}</span></h3>

      {/* Mostrar el total efectivo */}
      <h3>Total Efectivo: <span style={{ color: 'blue' }}>Q. {totalEfectivo.toFixed(2)}</span></h3>
    </Box>
  );
};

export default ReporteVentas;
