import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from './firebaseConfig'; // Importa tu configuración de Firebase

const Login = ({ onLogin }) => {
  const [pin, setPin] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async () => {
    try {
      const q = query(collection(db, 'Users'), where('pin', '==', pin));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setError('PIN incorrecto. Intente nuevamente.');
        return;
      }

      const userData = querySnapshot.docs[0].data();
      onLogin(userData.role); // Pasar el rol al componente App.js

    } catch (err) {
      console.error('Error durante el login: ', err);
      setError('Hubo un problema durante el login. Intente de nuevo.');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Typography variant="h4" gutterBottom>Login</Typography>
      <TextField
        label="Ingrese su PIN"
        type="password"
        value={pin}
        onChange={(e) => setPin(e.target.value)}
        margin="normal"
      />
      {error && <Typography color="error">{error}</Typography>}
      <Button variant="contained" color="primary" onClick={handleLogin} sx={{ mt: 2 }}>
        Iniciar Sesión
      </Button>
    </Box>
  );
};

export default Login;
