import React, { useState, useEffect } from 'react';
import { db } from './firebaseConfig';
import { collection, addDoc, onSnapshot, doc, updateDoc } from 'firebase/firestore';
import { Box, Button, TextField, Typography, Paper, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';

const RegistroTipoMaiz = () => {
  const [tipo, setTipo] = useState('');
  const [mensajeExito, setMensajeExito] = useState('');
  const [mensajeError, setMensajeError] = useState('');
  const [tiposMaiz, setTiposMaiz] = useState([]);
  const [idEditar, setIdEditar] = useState(null);

  // Obtener los tipos de maíz de Firestore
  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'tipos_maiz'), (snapshot) => {
      const tipos = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setTiposMaiz(tipos);
    });

    return () => unsubscribe();
  }, []);

  const registrarTipoMaiz = async () => {
    if (idEditar) {
      // Actualizar tipo de maíz
      const docRef = doc(db, 'tipos_maiz', idEditar);
      try {
        await updateDoc(docRef, { nombre: tipo });
        setMensajeExito('Tipo de Maíz actualizado con éxito');
        setTipo('');
        setIdEditar(null);
      } catch (error) {
        setMensajeError('Error al actualizar el tipo de maíz: ' + error.message);
      }
    } else {
      // Registrar nuevo tipo de maíz
      try {
        await addDoc(collection(db, 'tipos_maiz'), {
          nombre: tipo,
          activo: true, // Nuevo tipo de maíz está activo por defecto
        });
        setMensajeExito('Tipo de Maíz registrado con éxito');
        setMensajeError('');
        setTipo(''); // Limpiar campo
      } catch (error) {
        setMensajeError('Error al registrar el tipo de maíz: ' + error.message);
        setMensajeExito('');
      }
    }
  };

  // Función para desactivar o activar tipo de maíz
  const toggleActivoTipoMaiz = async (id, estadoActual) => {
    const docRef = doc(db, 'tipos_maiz', id);
    try {
      await updateDoc(docRef, { activo: !estadoActual });
      setMensajeExito(`Tipo de maíz ${estadoActual ? 'desactivado' : 'activado'} con éxito`);
    } catch (error) {
      setMensajeError('Error al cambiar el estado del tipo de maíz: ' + error.message);
    }
  };

  const editarTipoMaiz = (tipo) => {
    setTipo(tipo.nombre);
    setIdEditar(tipo.id);
  };

  return (
    <Box
      component={Paper}
      elevation={3}
      sx={{
        p: 4,
        maxWidth: 400,
        mx: 'auto',
        mt: 5,
        textAlign: 'center',
        borderRadius: 2,
      }}
    >
      <Typography variant="h4" component="h2" gutterBottom>
        {idEditar ? 'Actualizar Tipo de Maíz' : 'Registrar Tipo de Maíz'}
      </Typography>

      <TextField
        label="Tipo de Maíz"
        variant="outlined"
        value={tipo}
        onChange={(e) => setTipo(e.target.value)}
        fullWidth
        margin="normal"
        required
      />

      <Button
        variant="contained"
        color="primary"
        onClick={registrarTipoMaiz}
        fullWidth
        sx={{ mt: 2 }}
      >
        {idEditar ? 'Actualizar Tipo de Maíz' : 'Registrar Tipo de Maíz'}
      </Button>

      {mensajeExito && (
        <Typography variant="body1" color="success.main" sx={{ mt: 2 }}>
          {mensajeExito}
        </Typography>
      )}

      {mensajeError && (
        <Typography variant="body1" color="error.main" sx={{ mt: 2 }}>
          {mensajeError}
        </Typography>
      )}

      <Typography variant="h5" sx={{ mt: 4 }}>
        Tipos de Maíz Registrados
      </Typography>

      <Box sx={{ mt: 2 }}>
        {tiposMaiz.map((tipo) => (
          <Paper
            key={tipo.id}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: 2,
              mt: 1,
            }}
          >
            <Typography variant="body1">{tipo.nombre}</Typography>
            <Box>
              <IconButton
                color="primary"
                onClick={() => editarTipoMaiz(tipo)}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                color={tipo.activo ? 'success' : 'error'}
                onClick={() => toggleActivoTipoMaiz(tipo.id, tipo.activo)}
              >
                {tipo.activo ? <ToggleOnIcon /> : <ToggleOffIcon />}
              </IconButton>
            </Box>
          </Paper>
        ))}
      </Box>
    </Box>
  );
};

export default RegistroTipoMaiz;
