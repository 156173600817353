import React, { useState, useEffect } from 'react';
import { db } from './firebaseConfig';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { TextField, Button, Box, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

const ReporteDeudaCliente = () => {
  const [clientes, setClientes] = useState([]);
  const [clienteSeleccionado, setClienteSeleccionado] = useState(null);
  const [transacciones, setTransacciones] = useState([]); // Para almacenar ventas y abonos en orden cronológico
  const [totalVentasCredito, setTotalVentasCredito] = useState(0);
  const [totalAbonos, setTotalAbonos] = useState(0);
  const [deudaActual, setDeudaActual] = useState(0);

  // Cargar clientes activos al iniciar el componente
  useEffect(() => {
    const obtenerClientesActivos = async () => {
      const querySnapshot = await getDocs(query(collection(db, 'clientes'), where('activo', '==', true)));
      setClientes(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    obtenerClientesActivos();
  }, []);

  // Función para obtener las ventas a crédito de un cliente
  const obtenerVentasCredito = async (clienteId) => {
    const ventasRef = collection(db, 'ventas');
    const q = query(ventasRef, where('clienteId', '==', clienteId), where('tipoVenta', '==', 'Crédito'));
    const ventasSnapshot = await getDocs(q);

    let ventas = [];

    for (const ventaDoc of ventasSnapshot.docs) {
      const venta = ventaDoc.data();
      const bodegaRef = doc(db, 'bodegas', venta.bodegaId);
      const bodegaDoc = await getDoc(bodegaRef);
      const nombreBodega = bodegaDoc.exists() ? bodegaDoc.data().nombre : 'Bodega no encontrada';

      const detallesSnapshot = await getDocs(collection(db, `ventas/${ventaDoc.id}/detalles`));
      const detallesVenta = detallesSnapshot.docs.map((detalleDoc) => detalleDoc.data());

      detallesVenta.forEach((detalle) => {
        ventas.push({
          tipo: 'venta',
          fecha: venta.fecha,
          bodega: nombreBodega,
          cantidad: detalle.cantidad,
          tipoMaiz: detalle.nombre,
          precioVenta: detalle.precioVenta,
          subtotal: detalle.subtotal,
        });
      });
    }

    return ventas;
  };

  // Función para obtener los abonos realizados por un cliente que estén aprobados
  const obtenerAbonosCliente = async (clienteId) => {
    const abonosRef = collection(db, 'abonos'); // Asegúrate de que la colección sea 'abonos'
    const q = query(abonosRef, where('clienteId', '==', clienteId), where('estado', '==', 'Aprobado')); // Solo abonos aprobados
    const abonosSnapshot = await getDocs(q);

    let abonos = [];

    for (const abonoDoc of abonosSnapshot.docs) {
      const abono = abonoDoc.data();
      abonos.push({
        tipo: 'abono',
        fecha: abono.fecha,
        cantidad: abono.monto, // Usamos "monto" para el abono
      });
    }

    return abonos;
  };

  // Función para obtener las transacciones (ventas y abonos) de un cliente
  const consultarDeudaCliente = async () => {
    if (clienteSeleccionado) {
      // Obtener ventas y abonos
      const ventas = await obtenerVentasCredito(clienteSeleccionado.id);
      const abonos = await obtenerAbonosCliente(clienteSeleccionado.id);

      // Combinar ventas y abonos en una sola lista
      const todasTransacciones = [...ventas, ...abonos];

      // Ordenar por fecha
      todasTransacciones.sort((a, b) => new Date(a.fecha) - new Date(b.fecha));

      // Calcular totales
      const totalCredito = ventas.reduce((total, venta) => total + venta.subtotal, 0);
      const totalAbonos = abonos.reduce((total, abono) => total + abono.cantidad, 0);
      const deuda = totalCredito - totalAbonos;

      // Actualizar el estado
      setTransacciones(todasTransacciones);
      setTotalVentasCredito(totalCredito);
      setTotalAbonos(totalAbonos);
      setDeudaActual(deuda);
    }
  };

  return (
    <Box
      sx={{
        mt: 4,
        p: 3,
        backgroundColor: 'white',
        borderRadius: 2,
        boxShadow: 3,
        maxWidth: '800px',
        margin: '0 auto',
        width: '100%',
      }}
    >
      <Typography variant="h4" gutterBottom>
        Reporte de Deuda de Cliente
      </Typography>

      {/* Autocomplete para seleccionar cliente */}
      <Autocomplete
        options={clientes}
        getOptionLabel={(option) => option.nombre}
        value={clienteSeleccionado}
        onChange={(event, newValue) => setClienteSeleccionado(newValue)}
        renderInput={(params) => <TextField {...params} label="Seleccionar Cliente" margin="normal" />}
        fullWidth
      />

      <Button variant="contained" color="primary" fullWidth onClick={consultarDeudaCliente} sx={{ mt: 2 }}>
        Consultar Deuda
      </Button>

      {/* Mostrar las transacciones (ventas y abonos) */}
      <Typography variant="h6" sx={{ mt: 4 }}>
        Transacciones (Ventas y Abonos)
      </Typography>
      <Table sx={{ mt: 2 }}>
        <TableHead>
          <TableRow>
            <TableCell>Fecha</TableCell>
            <TableCell>Tipo</TableCell>
            <TableCell>Bodega / Descripción</TableCell>
            <TableCell>Cantidad</TableCell>
            <TableCell>Precio / Abono</TableCell>
            <TableCell>Subtotal</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transacciones.length > 0 ? (
            transacciones.map((transaccion, index) => (
              <TableRow key={index}>
                <TableCell>{transaccion.fecha}</TableCell>
                <TableCell>{transaccion.tipo === 'venta' ? 'Venta a Crédito' : 'Abono'}</TableCell>
                <TableCell>{transaccion.tipo === 'venta' ? transaccion.bodega : 'Abono de Cliente'}</TableCell>
                <TableCell>{transaccion.tipo === 'venta' ? transaccion.cantidad : '-'}</TableCell>
                <TableCell>
                  {transaccion.tipo === 'venta'
                    ? `Q. ${transaccion.precioVenta.toFixed(2)}`
                    : `Q. ${transaccion.cantidad.toFixed(2)}`}
                </TableCell>
                <TableCell>
                  {transaccion.tipo === 'venta' ? `Q. ${transaccion.subtotal.toFixed(2)}` : '-'}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6} align="center">
                No hay transacciones registradas
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      {/* Totales y deuda actual */}
      <Typography variant="h6" sx={{ mt: 4 }}>
        Total de Ventas al Crédito: Q. {totalVentasCredito.toFixed(2)}
      </Typography>
      <Typography variant="h6">
        Total de Abonos: Q. {totalAbonos.toFixed(2)}
      </Typography>
      <Typography variant="h6" sx={{ color: deudaActual > 0 ? 'red' : 'green' }}>
        Deuda Actual: Q. {deudaActual.toFixed(2)}
      </Typography>
    </Box>
  );
};

export default ReporteDeudaCliente;
