import React, { useState, useEffect } from 'react';
import { db } from './firebaseConfig';
import { collection, getDocs, query, where, doc, addDoc, updateDoc } from 'firebase/firestore';
import { Box, Button, TextField, Select, MenuItem, InputLabel, FormControl, Typography } from '@mui/material';

// Función para obtener la fecha en formato YYYY-MM-DD con la zona horaria local
const obtenerFechaLocal = () => {
  const fecha = new Date();
  const year = fecha.getFullYear();
  const month = (fecha.getMonth() + 1).toString().padStart(2, '0'); // Meses van de 0-11, se ajusta sumando 1
  const day = fecha.getDate().toString().padStart(2, '0'); // Día del mes
  return `${year}-${month}-${day}`;
};

const RegistroMaiz = () => {
  const [tipoMaiz, setTipoMaiz] = useState('');
  const [precioCompra, setPrecioCompra] = useState(0);
  const [precioVenta, setPrecioVenta] = useState(0);
  const [cantidad, setCantidad] = useState(0);
  const [bodegaSeleccionada, setBodegaSeleccionada] = useState('');
  const [tiposMaiz, setTiposMaiz] = useState([]);
  const [bodegas, setBodegas] = useState([]);
  const [fechaIngreso, setFechaIngreso] = useState(obtenerFechaLocal()); // Fecha actual por defecto
  const [camionadaNumero, setCamionadaNumero] = useState(1); // Inicializa con la camionada 1

  // Cargar tipos de maíz activos y bodegas desde Firebase
  useEffect(() => {
    const obtenerTiposMaiz = async () => {
      const q = query(collection(db, 'tipos_maiz'), where('activo', '==', true));
      const querySnapshot = await getDocs(q);
      setTiposMaiz(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    const obtenerBodegas = async () => {
      const querySnapshot = await getDocs(collection(db, 'bodegas'));
      setBodegas(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    obtenerTiposMaiz();
    obtenerBodegas();
  }, []);

  // Obtener el último número de camionada y asignar el siguiente número
  useEffect(() => {
    const obtenerNumeroCamionada = async () => {
      const querySnapshot = await getDocs(collection(db, 'camionadas'));
      const totalCamionadas = querySnapshot.docs.length;
      setCamionadaNumero(totalCamionadas + 1); // Asignar el siguiente número
    };

    obtenerNumeroCamionada();
  }, []);

  // Función para registrar el maíz como una nueva camionada
  const registrarMaiz = async () => {
    try {
      // Registrar nueva camionada en la colección `camionadas`
      await addDoc(collection(db, 'camionadas'), {
        tipoMaiz: tipoMaiz,
        precioCompra: parseFloat(precioCompra),
        precioVenta: parseFloat(precioVenta),
        cantidad: parseInt(cantidad),
        fechaIngreso: fechaIngreso, // Fecha de ingreso de la camionada
        camionadaNumero: camionadaNumero, // Número de camionada
        bodegaId: bodegaSeleccionada,
        activo: true // Marcar la camionada como activa
      });

      alert('Camionada registrada con éxito');

      // Limpiar campos del formulario
      setTipoMaiz('');
      setPrecioCompra(0);
      setPrecioVenta(0);
      setCantidad(0);
      setBodegaSeleccionada('');
      setFechaIngreso(obtenerFechaLocal()); // Restablecer la fecha a la fecha actual
      setCamionadaNumero(camionadaNumero + 1); // Incrementar el número de camionada
    } catch (error) {
      console.error('Error al registrar la camionada: ', error);
    }
  };

  return (
    <Box
      sx={{
        mt: 4,
        p: 3,
        backgroundColor: 'white',
        borderRadius: 2,
        boxShadow: 3,
        maxWidth: '600px',
        margin: '0 auto',
        width: '100%'
      }}
    >
      <Typography variant="h4" gutterBottom>Registrar Maíz en Bodega</Typography>

      <FormControl fullWidth margin="normal">
        <InputLabel>Bodega</InputLabel>
        <Select
          value={bodegaSeleccionada}
          onChange={(e) => setBodegaSeleccionada(e.target.value)}
        >
          <MenuItem value="">
            <em>Seleccione una bodega</em>
          </MenuItem>
          {bodegas.map((bodega) => (
            <MenuItem key={bodega.id} value={bodega.id}>
              {bodega.nombre}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth margin="normal">
        <InputLabel>Tipo de Maíz</InputLabel>
        <Select
          value={tipoMaiz}
          onChange={(e) => setTipoMaiz(e.target.value)}
        >
          <MenuItem value="">
            <em>Seleccione un tipo de maíz</em>
          </MenuItem>
          {tiposMaiz.map((maiz) => (
            <MenuItem key={maiz.id} value={maiz.id}>
              {maiz.nombre}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        fullWidth
        label="Precio de Compra"
        type="number"
        value={precioCompra}
        onChange={(e) => setPrecioCompra(e.target.value)}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Precio de Venta"
        type="number"
        value={precioVenta}
        onChange={(e) => setPrecioVenta(e.target.value)}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Cantidad a ingresar (en quintales)"
        type="number"
        value={cantidad}
        onChange={(e) => setCantidad(e.target.value)}
        margin="normal"
      />

      <TextField
        fullWidth
        label="Fecha de Ingreso"
        type="date"
        value={fechaIngreso}
        onChange={(e) => setFechaIngreso(e.target.value)}
        margin="normal"
      />

      <Typography variant="h6" gutterBottom>Número de Camionada: {camionadaNumero}</Typography>

      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={registrarMaiz}
        sx={{ mt: 2 }}
      >
        Registrar Maíz en Bodega
      </Button>

      {/* Vista previa de los datos ingresados */}
      {bodegaSeleccionada && tipoMaiz && cantidad > 0 && (
        <Box sx={{ mt: 3, p: 2, backgroundColor: '#f1f1f1', borderRadius: 2 }}>
          <Typography variant="h6">Vista previa</Typography>
          <Typography><strong>Bodega:</strong> {bodegas.find(b => b.id === bodegaSeleccionada)?.nombre}</Typography>
          <Typography><strong>Tipo de Maíz:</strong> {tiposMaiz.find(t => t.id === tipoMaiz)?.nombre}</Typography>
          <Typography><strong>Cantidad:</strong> {cantidad}</Typography>
          <Typography><strong>Precio de Compra:</strong> Q.{precioCompra}</Typography>
          <Typography><strong>Precio de Venta:</strong> Q.{precioVenta}</Typography>
          <Typography><strong>Fecha de Ingreso:</strong> {fechaIngreso}</Typography>
          <Typography><strong>Camionada Número:</strong> {camionadaNumero}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default RegistroMaiz;
