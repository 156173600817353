import React, { useState, useEffect } from 'react';
import { BottomNavigation, BottomNavigationAction, Box, Menu, MenuItem, Typography, Button } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ReportIcon from '@mui/icons-material/Report';
import SettingsIcon from '@mui/icons-material/Settings';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

import RegistroCliente from './RegistroCliente';
import RegistroMaiz from './RegistroMaiz';
import RegistrarVenta from './RegistrarVenta';
import AgregarBodega from './AgregarBodega';
import RegistroTipoMaiz from './RegistroTipoMais';
import VistaStockBodega from './VistaStockBodega';
import RegistrarCompra from './RegistrarCompra';
import ReporteVentasPorFecha from './ReporteVentasPorFecha';
import RegistrarGastosAbonos from './RegistrarGastosAbonos';
import RegistrarAbono from './RegistrarAbono';
import AdminAbonos from './AdminAbonos';
import VistaClientes from './VistaClientes';
import ReporteDeudaCliente from './ReporteDeudaCliente';
import ReporteCompras from './ReporteCompras';
import ReporteGastosAbonos from './ReporteGastosAbonos';
import Login from './Login';

import logo from './imagenes/logo.png';

function App() {
  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeMenu, setActiveMenu] = useState(null);
  const [selectedView, setSelectedView] = useState(''); 
  const [userRole, setUserRole] = useState(null); 
  const [isAuthenticated, setIsAuthenticated] = useState(false); 

  useEffect(() => {
    const role = localStorage.getItem('userRole'); 
    if (role) {
      setUserRole(role); 
      setIsAuthenticated(true); 
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('userRole'); 
    setIsAuthenticated(false); 
    setUserRole(null); 
  };

  const handleOpenMenu = (event, menuType) => {
    setAnchorEl(event.currentTarget);
    setActiveMenu(menuType);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setActiveMenu(null);
  };

  const renderComponent = () => {
    switch (selectedView) {
      case 'RegistroCliente':
        return <RegistroCliente />;
      case 'RegistroMaiz':
        return userRole === 'admin' ? <RegistroMaiz /> : <Typography>No tienes acceso a esta sección.</Typography>;
      case 'RegistrarVenta':
        return <RegistrarVenta />;
      case 'AgregarBodega':
        return userRole === 'admin' ? <AgregarBodega /> : <Typography>No tienes acceso a esta sección.</Typography>;
      case 'RegistroTipoMaiz':
        return userRole === 'admin' ? <RegistroTipoMaiz /> : <Typography>No tienes acceso a esta sección.</Typography>;
      case 'VistaStockBodega':
        return <VistaStockBodega />;
      case 'RegistrarCompra':
        return userRole === 'admin' ? <RegistrarCompra /> : <Typography>No tienes acceso a esta sección.</Typography>;
      case 'ReporteVentasPorFecha':
        return userRole === 'admin' ? <ReporteVentasPorFecha /> : <Typography>No tienes acceso a esta sección.</Typography>;
      case 'RegistrarGastosAbonos':
        return userRole === 'admin' ? <RegistrarGastosAbonos /> : <Typography>No tienes acceso a esta sección.</Typography>;
      case 'RegistrarAbono':
        return <RegistrarAbono />;
      case 'AdminAbonos':
        return userRole === 'admin' ? <AdminAbonos /> : <Typography>No tienes acceso a esta sección.</Typography>;
      case 'VistaClientes':
        return <VistaClientes />;
      case 'ReporteDeudaCliente':
        return userRole === 'admin' ? <ReporteDeudaCliente /> : <Typography>No tienes acceso a esta sección.</Typography>;
      case 'ReporteCompras': 
        return <ReporteCompras />;
      case 'ReporteGastosAbonos': 
        return <ReporteGastosAbonos />;
      default:
        return <Typography variant="h6">Seleccione una opción</Typography>;
    }
  };

  const renderMenuItems = () => {
    switch (activeMenu) {
      case 'ingresos':
        return (
          <>
            {userRole === 'admin' && (
              <MenuItem onClick={() => { setSelectedView('RegistrarCompra'); handleCloseMenu(); }}>Compras</MenuItem>
            )}
            <MenuItem onClick={() => { setSelectedView('RegistrarAbono'); handleCloseMenu(); }}>Abonos de Clientes</MenuItem>
          </>
        );
      case 'egresos':
        return (
          <>
            <MenuItem onClick={() => { setSelectedView('RegistrarVenta'); handleCloseMenu(); }}>Ventas</MenuItem>
            {userRole === 'admin' && (
              <>
                <MenuItem onClick={() => { setSelectedView('RegistrarGastosAbonos'); handleCloseMenu(); }}>Abonos de Empleados</MenuItem>
                <MenuItem onClick={() => { setSelectedView('RegistrarGastosAbonos'); handleCloseMenu(); }}>Gastos</MenuItem>
              </>
            )}
          </>
        );
      case 'reportes':
        return (
          <>
            {userRole === 'admin' && (
              <>
                <MenuItem onClick={() => { setSelectedView('ReporteVentasPorFecha'); handleCloseMenu(); }}>Reporte de Ventas</MenuItem>
                <MenuItem onClick={() => { setSelectedView('ReporteCompras'); handleCloseMenu(); }}>Reporte de Compras</MenuItem>
                <MenuItem onClick={() => { setSelectedView('ReporteGastosAbonos'); handleCloseMenu(); }}>Reporte de Gastos y Abonos</MenuItem>
              </>
            )}
            <MenuItem onClick={() => { setSelectedView('VistaClientes'); handleCloseMenu(); }}>Listado de Clientes</MenuItem>
            <MenuItem onClick={() => { setSelectedView('VistaStockBodega'); handleCloseMenu(); }}>Existencia por Bodega</MenuItem>
            {userRole === 'admin' && (
              <MenuItem onClick={() => { setSelectedView('ReporteDeudaCliente'); handleCloseMenu(); }}>Reporte de Deuda de Cliente</MenuItem>
            )}
          </>
        );
      case 'mantenimiento':
        return (
          <>
            {userRole === 'admin' && (
              <>
                <MenuItem onClick={() => { setSelectedView('AdminAbonos'); handleCloseMenu(); }}>Administrar Abonos</MenuItem>
                <MenuItem onClick={() => { setSelectedView('AgregarBodega'); handleCloseMenu(); }}>Agregar Bodega</MenuItem>
                <MenuItem onClick={() => { setSelectedView('RegistroMaiz'); handleCloseMenu(); }}>Registrar Maíz</MenuItem>
                <MenuItem onClick={() => { setSelectedView('RegistroCliente'); handleCloseMenu(); }}>Registrar Cliente</MenuItem>
                <MenuItem onClick={() => { setSelectedView('RegistroTipoMaiz'); handleCloseMenu(); }}>Registrar Tipo Maiz</MenuItem>
              </>
            )}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        position: 'relative', 
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: `url(${logo})`,
          backgroundSize: '50%',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          opacity: 0.1, // Solo la imagen es transparente
          zIndex: -1, // Coloca la imagen detrás del contenido
        }}
      />
      {!isAuthenticated ? (
        <Login onLogin={(role) => { setUserRole(role); setIsAuthenticated(true); }} />
      ) : (
        <>
          <Box sx={{ padding: 2 }}>
            {renderComponent()}
          </Box>

          <BottomNavigation
            sx={{ width: '100%', position: 'fixed', bottom: 0 }}
            value={value}
            onChange={(event, newValue) => setValue(newValue)}
          >
            <BottomNavigationAction
              label="Ingresos"
              icon={<AttachMoneyIcon />}
              onClick={(e) => handleOpenMenu(e, 'ingresos')}
            />
            <BottomNavigationAction
              label="Egresos"
              icon={<ShoppingCartIcon />}
              onClick={(e) => handleOpenMenu(e, 'egresos')}
            />
            <BottomNavigationAction
              label="Reportes"
              icon={<ReportIcon />}
              onClick={(e) => handleOpenMenu(e, 'reportes')}
            />
            <BottomNavigationAction
              label="Mantenimiento"
              icon={<SettingsIcon />}
              onClick={(e) => handleOpenMenu(e, 'mantenimiento')}
            />
            <BottomNavigationAction
              label="Cerrar Sesión"
              icon={<PowerSettingsNewIcon />}
              onClick={handleLogout}
            />
          </BottomNavigation>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            {renderMenuItems()}
          </Menu>
        </>
      )}
    </Box>
  );
}

export default App;
