import React, { useState, useEffect } from 'react';
import { db } from './firebaseConfig';
import { collection, getDocs, updateDoc, doc, deleteDoc, getDoc, query, where } from 'firebase/firestore';
import { Box, Table, TableHead, TableBody, TableRow, TableCell, Button, TextField, Autocomplete } from '@mui/material';

// Función para obtener la fecha del dispositivo en formato YYYY-MM-DD
const obtenerFechaActual = () => {
  const fecha = new Date();
  const year = fecha.getFullYear();
  const month = (fecha.getMonth() + 1).toString().padStart(2, '0');
  const day = fecha.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const AdminAbonos = () => {
  const [abonos, setAbonos] = useState([]);
  const [clientes, setClientes] = useState([]); // Para almacenar los clientes
  const [clienteSeleccionado, setClienteSeleccionado] = useState(null);
  const [fechaSeleccionada, setFechaSeleccionada] = useState(obtenerFechaActual()); // Fecha predeterminada a la fecha del dispositivo
  const [abonosFiltrados, setAbonosFiltrados] = useState([]);

  // Cargar abonos y clientes
  useEffect(() => {
    const obtenerAbonos = async () => {
      const querySnapshot = await getDocs(collection(db, 'abonos'));
      setAbonos(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    const obtenerClientes = async () => {
      const querySnapshot = await getDocs(query(collection(db, 'clientes'), where('activo', '==', true))); // Filtrar clientes activos
      setClientes(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    obtenerAbonos();
    obtenerClientes(); // Cargar clientes para tener los nombres
  }, []);

  // Función para aprobar el abono
  const aprobarAbono = async (abono) => {
    try {
      const clienteRef = doc(db, 'clientes', abono.clienteId);
      const clienteDoc = await getDoc(clienteRef);

      if (clienteDoc.exists()) {
        const clienteData = clienteDoc.data();
        const deudaActual = clienteData.deuda || 0;

        // Reducir la deuda con el monto del abono
        const nuevaDeuda = deudaActual - abono.monto;

        // Actualizar la deuda del cliente
        await updateDoc(clienteRef, {
          deuda: nuevaDeuda >= 0 ? nuevaDeuda : 0, // Asegurarse de que la deuda no sea negativa
        });

        // Marcar el abono como aprobado
        const abonoRef = doc(db, 'abonos', abono.id);
        await updateDoc(abonoRef, {
          estado: 'Aprobado',
        });

        alert('Abono aprobado y deuda actualizada.');
        setAbonos(abonos.map(a => (a.id === abono.id ? { ...a, estado: 'Aprobado' } : a)));
      }
    } catch (error) {
      console.error('Error al aprobar el abono: ', error);
    }
  };

  // Función para eliminar el abono
  const eliminarAbono = async (abonoId) => {
    try {
      await deleteDoc(doc(db, 'abonos', abonoId));
      setAbonos(abonos.filter(abono => abono.id !== abonoId));
      alert('Abono eliminado correctamente.');
    } catch (error) {
      console.error('Error al eliminar el abono:', error);
    }
  };

  // Función para obtener el nombre del cliente desde el estado de clientes
  const obtenerNombreCliente = (clienteId) => {
    const cliente = clientes.find(cliente => cliente.id === clienteId);
    return cliente ? cliente.nombre : 'Desconocido';
  };

  // Función para aplicar los filtros de fecha y cliente
  const aplicarFiltros = () => {
    const abonosFiltrados = abonos.filter(abono => {
      const coincideCliente = clienteSeleccionado ? abono.clienteId === clienteSeleccionado.id : true;
      const coincideFecha = abono.fecha === fechaSeleccionada;
      return coincideCliente && coincideFecha;
    });
    setAbonosFiltrados(abonosFiltrados);
  };

  // Aplicar filtros cuando cambie la selección de cliente o fecha
  useEffect(() => {
    aplicarFiltros();
  }, [abonos, clienteSeleccionado, fechaSeleccionada]);

  return (
    <Box sx={{ mt: 4, p: 3, backgroundColor: 'white', borderRadius: 2, boxShadow: 3, maxWidth: '800px', margin: '0 auto', width: '100%' }}>
      <h2>Administrar Abonos</h2>

      {/* Campo para seleccionar cliente */}
      <Autocomplete
        options={clientes}
        getOptionLabel={(option) => option.nombre}
        value={clienteSeleccionado}
        onChange={(event, newValue) => setClienteSeleccionado(newValue)}
        renderInput={(params) => <TextField {...params} label="Seleccionar Cliente" margin="normal" fullWidth />}
      />

      {/* Campo para seleccionar fecha */}
      <TextField
        label="Seleccionar Fecha"
        type="date"
        fullWidth
        value={fechaSeleccionada}
        onChange={(e) => setFechaSeleccionada(e.target.value)}
        margin="normal"
      />

      {/* Tabla para mostrar abonos filtrados */}
      <Table sx={{ mt: 2 }}>
        <TableHead>
          <TableRow>
            <TableCell>Cliente</TableCell>
            <TableCell>Monto</TableCell>
            <TableCell>Fecha</TableCell>
            <TableCell>Estado</TableCell>
            <TableCell>Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {abonosFiltrados.map(abono => (
            <TableRow key={abono.id}>
              <TableCell>{obtenerNombreCliente(abono.clienteId)}</TableCell> {/* Muestra el nombre del cliente */}
              <TableCell>{abono.monto}</TableCell>
              <TableCell>{abono.fecha}</TableCell>
              <TableCell>{abono.estado}</TableCell>
              <TableCell>
                {abono.estado !== 'Aprobado' && (
                  <Button variant="contained" color="primary" onClick={() => aprobarAbono(abono)}>
                    Aprobar
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={abono.estado !== 'Aprobado'} // Deshabilitado si no está aprobado
                  onClick={() => eliminarAbono(abono.id)}
                  sx={{ ml: 2 }}
                >
                  Eliminar
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default AdminAbonos;
