import React, { useState } from 'react';
import { db } from './firebaseConfig';
import { TextField, Button, Box } from '@mui/material';
import { addDoc, collection } from 'firebase/firestore';

const RegistrarGastosAbonos = () => {
  const [gasto, setGasto] = useState({ concepto: '', monto: 0 });
  const [abono, setAbono] = useState({ empleado: '', monto: 0 });

  // Función para obtener la fecha del dispositivo
  const obtenerFechaLocal = () => {
    const fecha = new Date();
    const year = fecha.getFullYear();
    const month = (fecha.getMonth() + 1).toString().padStart(2, '0'); // Meses de 0-11
    const day = fecha.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const registrarGasto = async () => {
    try {
      // Obtener la fecha local del dispositivo
      const fechaDispositivo = obtenerFechaLocal();

      // Registrar el gasto en Firebase
      await addDoc(collection(db, 'gastos'), {
        fecha: fechaDispositivo, // Usar la fecha local
        descripcion: gasto.concepto,
        monto: parseFloat(gasto.monto),
      });

      alert('Gasto registrado con éxito');
      setGasto({ concepto: '', monto: 0 });
    } catch (error) {
      console.error('Error al registrar el gasto: ', error);
    }
  };

  const registrarAbono = async () => {
    try {
      // Obtener la fecha local del dispositivo
      const fechaDispositivo = obtenerFechaLocal();

      // Registrar el abono en Firebase
      await addDoc(collection(db, 'abono_empleados'), {
        fecha: fechaDispositivo, // Usar la fecha local
        empleado: abono.empleado,
        monto: parseFloat(abono.monto),
      });

      alert('Abono a empleado registrado con éxito');
      setAbono({ empleado: '', monto: 0 });
    } catch (error) {
      console.error('Error al registrar el abono: ', error);
    }
  };

  return (
    <Box
      sx={{
        mt: 4,
        p: 3,
        backgroundColor: 'white',
        borderRadius: 2,
        boxShadow: 3,
        maxWidth: '800px',
        margin: '0 auto',
        width: '100%',
      }}
    >
      <h2>Registrar Gasto</h2>
      <TextField
        fullWidth
        label="Concepto"
        value={gasto.concepto}
        onChange={(e) => setGasto({ ...gasto, concepto: e.target.value })}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Monto"
        type="number"
        value={gasto.monto}
        onChange={(e) => setGasto({ ...gasto, monto: parseFloat(e.target.value) || 0 })}
        margin="normal"
      />
      <Button variant="contained" color="error" fullWidth onClick={registrarGasto} sx={{ mt: 2 }}>
        Registrar Gasto
      </Button>

      <h2>Registrar Abono a Empleado</h2>
      <TextField
        fullWidth
        label="Empleado"
        value={abono.empleado}
        onChange={(e) => setAbono({ ...abono, empleado: e.target.value })}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Monto"
        type="number"
        value={abono.monto}
        onChange={(e) => setAbono({ ...abono, monto: parseFloat(e.target.value) || 0 })}
        margin="normal"
      />
      <Button variant="contained" color="primary" fullWidth onClick={registrarAbono} sx={{ mt: 2 }}>
        Registrar Abono
      </Button>
    </Box>
  );
};

export default RegistrarGastosAbonos;
